<template>
	<section class="navigator-base">
		<NavigatorBaseHeader @set-tab-focus="setTabFocus($event)" />
		<NavigatorBaseBody ref="navigator-body" />
	</section>
</template>

<script>
import NavigatorBaseHeader from "./NavigatorBaseHeader.vue"
import NavigatorBaseBody from "./NavigatorBaseBody.vue"
import { mapActions, mapGetters } from "vuex"
import axios from "axios"

export default {
	components: {
		NavigatorBaseHeader,
		NavigatorBaseBody
	},
	methods: {
		...mapActions(["resetState", "addNewTab", "setActiveTab", "setNavigatorData"]),
		setTabFocus(tab) {
			this.$refs["navigator-body"].setTabFocus(tab)
		},
		fetchProfile() {
			let baseURL = "/user/profile"

			axios.get(baseURL).then(() => {})
		}
	},
	mounted() {
		this.addNewTab()
		this.setActiveTab(0)
	},
	unmounted() {
		this.resetState()
	},
	computed: {
		...mapGetters(["accessToken"])
	}
}
</script>

<style lang="scss" scoped>
.navigator-base {
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;
}
</style>
