<template>
	<div class="navigator-body">
		<NavigatorBaseBodyItem
			v-show="tabConfig[activeTab]?.id === item.id"
			v-for="item in tabConfig"
			:key="item.id"
			:ref="item.id"
			:refID="item.id"
			:tab-details="item"
		/>
	</div>
</template>

<script>
import NavigatorBaseBodyItem from "@/views/navigator/NavigatorBaseBodyItem.vue"
import { mapGetters } from "vuex"

export default {
	components: {
		NavigatorBaseBodyItem
	},
	methods: {
		setTabFocus(tab) {
			console.log("SET TAB FOCUS: ", tab.title)
		}
	},
	computed: {
		...mapGetters(["activeTab", "tabConfig"])
	}
}
</script>

<style lang="scss" scoped>
.navigator-body {
	min-width: 800px;
	position: relative;
	height: $navigator-body-height;
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
</style>
